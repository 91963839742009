<template>
  <div>
    <b-modal id="submitModal" title="Submitted" ok-only>
      <p class="my-4">Your support ticket has been submitted</p>
    </b-modal>

    <b-container class="mt-5">
      <b-row>
        <b-col>
          <h2>Support</h2>
        </b-col>
      </b-row>

      <b-form class="mt-5" @submit.prevent="submitTicket">
        <b-form-group
          id="priorityInputGroup"
          label="Priority"
          label-for="priorityInput"
        >
          <b-form-select
            id="priorityInput"
            v-model="form.priority"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="subjectInputGroup"
          label="Subject"
          label-for="subjectInput"
        >
          <b-form-input
            id="subjectInput"
            v-model="form.subject"
            :options="options"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="detailsInputGroup"
          label="Details"
          label-for="detailsInput"
        >
          <b-form-textarea
            id="detailsInput"
            v-model="form.html_text"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-button block variant="primary" class="mb-3" type="submit"
          >Submit<b-spinner v-if="submitting" small
        /></b-button>
      </b-form>
    </b-container>

    <b-container class="mt-5">
      <b-row align-h="center" class="mb-3">
        <b-button @click="showTickets = !showTickets" variant="link">Show Tickets</b-button>
      </b-row>
      <b-table
        v-if="showTickets"
        :items="provider"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        show-empty
      >
      </b-table>

      <b-row align-h="between" class="mt-3" v-if="showTickets">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
        <b-col cols="*" class="mr-3"> {{ rows }} tickets </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitting: false,
      showTickets: false,
      form: {
        priority: "LOW",
        subject: "",
        html_text: "",
        requester_name: "",
        requester_email: "",
        groupID: "5988290775351296",
      cc_emails: [],
      labels: null
      },
      options: [
        { value: "LOW", text: "Low" },
        { value: "MEDIUM", text: "Medium" },
        { value: "HIGH", text: "High" },
      ],
      fields: [
        { key: "subject", label: "Subject", sortable: true },
        { key: "status", label: "Status" },
      ],
      currentPage: 1,
      perPage: 15,
      rows: 0,
    };
  },
  methods: {
    async submitTicket() {
      this.submitting = true;
      let response = await this.$http.get(
        this.$store.state.apiURL + "/account",
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );

      this.form.requester_name = response.data.first_name;
      this.form.requester_email = response.data.email;

      try {
        let response = await this.$http.post(
          //API endpoint + '/api/sale/ticket',
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
      } catch (error) {
        this.handleError(error);
      }

      this.submitting = false;

      this.$bvModal.show("submitModal");
    },
    async provider() {
      try {
        let res = await this.$http.get(this.$store.state.apiURL + "/account", {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        });

        let response = await this.$http.get(
          this.$store.state.apiURL + "/support-tickets/" + res.data.email,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.currentPage = response.data.page.position.current;
        this.rows = response.data.page.data.total;

        return response.data.data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
  },
};
</script>
